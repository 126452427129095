/* Installer/home page */
import { VERSION } from '../../defs'

// FIXME: building of loader is not working. (The loader seldom changes, though)
// import loaderBody from 'bundle-text:../../bookmarklet.js'
const loaderBody = '!function(){const e="__tracking_lens__",n=document.getElementById(e);if(n&&window._tlens)window._tlens.toggle(),document.body.removeChild(n);else{const n=document.createElement("script");n.id=e,n.type="module",n.src="//lens.tracking.ringieraxelspringer.tech/lens/core.js?cb="+ +new Date,document.body.appendChild(n)}}();'
document.getElementById('loader').href = 'javascript: void ' + encodeURIComponent(loaderBody)

const footer = document.getElementById('version')
footer.innerText = `v${VERSION}`
